import automessages from "api/Automator";
import {
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  OBJECT,
  STRING,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";

// ------------------------------------
// Constants
// ------------------------------------

const GET_ALL_AUTOMATOR = "GET_ALL_AUTOMATOR";
const GET_ALL_AUTOMATOR_TRUE = "GET_ALL_AUTOMATOR_TRUE";
const DELETE_AUTOMATOR = "DELETE_AUTOMATOR";
const DUPLICATE_AUTOMATOR = "DUPLICATE_AUTOMATOR";
const EDIT_AUTOMATOR = "EDIT_AUTOMATOR";
const CREATE_AUTOMATOR = "CREATE_AUTOMATOR";
const GET_CURRENT_AUTOMATOR = "GET_CURRENT_AUTOMATOR";
const REMOVE_CURRENT_AUTOMATOR = "REMOVE_CURRENT_AUTOMATOR";
const GET_AUTOMATOR_ANALYTICS = "GET_AUTOMATOR_ANALYTICS";
const GET_INVOCATIONS = "GET_INVOCATIONS";
const AUTOMATOR_TOGGLE_ROW_ACTION_MENU = "AUTOMATOR_TOGGLE_ROW_ACTION_MENU";
const CUSTOMER_AUTOMATOR_CHECK_DATATABLE_ROW =
  "CUSTOMER_AUTOMATOR_CHECK_DATATABLE_ROW";
const CUSTOMER_AUTOMATOR_COLUMN_TOGGLE = "CUSTOMER_AUTOMATOR_COLUMN_TOGGLE";
const CUSTOMER_AUTOMATOR_RESET_COLUMNS = "CUSTOMER_AUTOMATOR_RESET_COLUMNS";
const SORT_CUSTOMER_AUTOMATOR = "SORT_CUSTOMER_AUTOMATOR";
const CUSTOMER_AUTOMATOR_CHECK_ALL_TABLE_ROWS =
  "CUSTOMER_AUTOMATOR_CHECK_ALL_TABLE_ROWS";
const CUSTOMER_AUTOMATOR_CLEAR_ACTION_MENU =
  "CUSTOMER_AUTOMATOR_CLEAR_ACTION_MENU";
const DELETE_AUTOMATOR_BULK = "DELETE_AUTOMATOR_BULK";

// ------------------------------------
// Actions
// ------------------------------------

export function getSuitAutomator(suitId, page) {
  return {
    type: GET_ALL_AUTOMATOR,
    payload: automessages.getSuitAutomator(suitId, page),
  };
}

export function getSuitAutomatorTrue(suitId) {
  return {
    type: GET_ALL_AUTOMATOR_TRUE,
    payload: automessages.getSuitAutomatorTrue(suitId),
  };
}

export function deleteAutomator(suitId, automessageId) {
  return {
    type: DELETE_AUTOMATOR,
    payload: automessages.deleteAutomator(suitId, automessageId),
  };
}

// automessageIds = {"automessage_ids": ["test", "test"]}
export function deleteAutomatorBulk(suitId, automessageIds) {
  return {
    type: DELETE_AUTOMATOR_BULK,
    payload: automessages.deleteAutomatorBulk(suitId, automessageIds),
  };
}

export function getCurrentAutomator(suitId, automessageId) {
  return {
    type: GET_CURRENT_AUTOMATOR,
    payload: automessages.getCurrentAutomator(suitId, automessageId),
  };
}

export function editAutomator(suitId, automessageId, data) {
  return {
    type: EDIT_AUTOMATOR,
    payload: automessages.editAutomator(suitId, automessageId, data),
  };
}

export const duplicateAutomator = (automator) => async (dispatch) => {
  return dispatch({
    type: DUPLICATE_AUTOMATOR,
    payload: automator,
  });
};

export function createAutomator(suitId, data, apiKey) {
  return {
    type: CREATE_AUTOMATOR,
    payload: automessages.createAutomator(suitId, data, apiKey),
  };
}

export function getAutomatorAnalytics(suitId, automessageId) {
  return {
    type: GET_AUTOMATOR_ANALYTICS,
    payload: automessages.getAutomatorAnalytics(suitId, automessageId),
  };
}

export function removeCurrentAutomator() {
  return {
    type: REMOVE_CURRENT_AUTOMATOR,
  };
}

export function toggleRowActionMenu(index) {
  return {
    type: AUTOMATOR_TOGGLE_ROW_ACTION_MENU,
    payload: index,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: CUSTOMER_AUTOMATOR_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function onColumnToggle(idx) {
  return {
    type: CUSTOMER_AUTOMATOR_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: CUSTOMER_AUTOMATOR_RESET_COLUMNS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_CUSTOMER_AUTOMATOR,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

export function checkAllTableRows(newChecked) {
  return {
    type: CUSTOMER_AUTOMATOR_CHECK_ALL_TABLE_ROWS,
    payload: newChecked,
  };
}
export function clearActionMenu(idx) {
  return {
    type: CUSTOMER_AUTOMATOR_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

// -------------------------------------
// Data table functions
//--------------------------------------

const ANALYTICS_CHECK_DATA_TABLE_ROW = "ANALYTICS_CHECK_DATA_TABLE_ROW";
const ANALYTICS_CHECK_ALL_DATA_TABLE_ROWS =
  "ANALYTICS_CHECK_ALL_DATA_TABLE_ROWS";
const ANALYTICS_ON_COLUMN_TOGGLE = "ANALYTICS_ON_COLUMN_TOGGLE";
const ANALYTICS_TOGGLE_ROW_ACTION_MENU = "ANALYTICS_TOGGLE_ROW_ACTION_MENU";
const ANALYTICS_CLEAR_ACTION_MENU = "ANALYTICS_CLEAR_ACTION_MENU";
const ANALYTICS_ON_RESET_COLUMNS = "ANALYTICS_ON_RESET_COLUMNS";
const SORT_ANALYTICS_HIT_AT = "SORT_ANALYTICS_HIT_AT";

export function analyticsCheckDataTableRow(idx) {
  return {
    type: ANALYTICS_CHECK_DATA_TABLE_ROW,
    payload: idx,
  };
}
export function analyticsCheckAllTableRows(newChecked) {
  return {
    type: ANALYTICS_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}
export function analyticsOnColumnToggle(idx) {
  return {
    type: ANALYTICS_ON_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function analyticsToggleRowActionMenu(idx) {
  return {
    type: ANALYTICS_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function analyitcsClearActionMenu(idx) {
  return {
    type: ANALYTICS_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function analyticsOnResetColumns() {
  return {
    type: ANALYTICS_ON_RESET_COLUMNS,
  };
}

export const sortAnalyticsTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_ANALYTICS_HIT_AT,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  automessages: null,
  automator: null,
  statistics: null,
  analytics: null,
  duplicate: null,
  isPending: false,
  error: false,
  count: 0,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function automatorReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_ALL_AUTOMATOR}_PENDING`:
    case `${GET_ALL_AUTOMATOR_TRUE}_PENDING`:
    case `${DELETE_AUTOMATOR}_PENDING`:
    case `${DUPLICATE_AUTOMATOR}_PENDING`:
    case `${DELETE_AUTOMATOR_BULK}_PENDING`:
    case `${EDIT_AUTOMATOR}_PENDING`:
    case `${GET_AUTOMATOR_ANALYTICS}_PENDING`:
    case `${CREATE_AUTOMATOR}_PENDING`:
    case `${GET_CURRENT_AUTOMATOR}_PENDING`:
    case `${GET_INVOCATIONS}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_ALL_AUTOMATOR}_REJECTED`:
    case `${GET_ALL_AUTOMATOR_TRUE}_REJECTED`:
    case `${DELETE_AUTOMATOR}_REJECTED`:
    case `${DUPLICATE_AUTOMATOR}_REJECTED`:
    case `${DELETE_AUTOMATOR_BULK}_REJECTED`:
    case `${EDIT_AUTOMATOR}_REJECTED`:
    case `${GET_AUTOMATOR_ANALYTICS}_REJECTED`:
    case `${CREATE_AUTOMATOR}_REJECTED`:
    case `${GET_CURRENT_AUTOMATOR}_REJECTED`:
    case `${GET_INVOCATIONS}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case `${GET_ALL_AUTOMATOR}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        automessages: prepareTableDate(action.payload.body.automessages),
        count: action.payload.body.count,
      };
    case `${GET_ALL_AUTOMATOR_TRUE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        automessages: action.payload.body.automessages,
      };
    case `${GET_CURRENT_AUTOMATOR}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        automator: action.payload.body,
      };
    case `${DELETE_AUTOMATOR}_FULFILLED`:
    case `${DELETE_AUTOMATOR_BULK}_FULFILLED`:
    case `${EDIT_AUTOMATOR}_FULFILLED`:
    case `${CREATE_AUTOMATOR}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case REMOVE_CURRENT_AUTOMATOR:
      return {
        ...state,
        automessages: null,
        automator: null,
        statistics: null,
        analytics: null,
      };
    case `${GET_AUTOMATOR_ANALYTICS}_FULFILLED`:
      return {
        ...state,
        analytics: action.payload.body,
        isPending: false,
      };
    case DUPLICATE_AUTOMATOR: {
      return {
        ...state,
        isPending: false,
        duplicate: action.payload,
      };
    }
    case `${GET_INVOCATIONS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        automessages: action.payload.body.campaigns,
      };

    case AUTOMATOR_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        automessages: {
          ...state.automessages,
          rows: state.automessages.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.automessages.rows[rowIdx],
                  actionsMenu: !state.automessages.rows[rowIdx].actionsMenu,
                }
              : state.automessages.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_AUTOMATOR_CHECK_DATATABLE_ROW:
      return {
        ...state,
        automessages: {
          ...state.automessages,
          rows: state.automessages.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.automessages.rows[rowIdx],
                  checked: !state.automessages.rows[rowIdx].checked,
                }
              : state.automessages.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_AUTOMATOR_COLUMN_TOGGLE:
      return {
        ...state,
        automessages: {
          ...state.automessages,
          labels: state.automessages.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };

    case CUSTOMER_AUTOMATOR_RESET_COLUMNS:
      return {
        ...state,
        automessages: {
          ...state.automessages,
          labels: state.automessages.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };

    case SORT_CUSTOMER_AUTOMATOR: {
      const indexOfKey = state.automessages.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.automessages.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.automessages.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.automessages.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.automessages.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        automessages: {
          ...state.automessages,
          rows: tableRows,
        },
      };
    }
    case CUSTOMER_AUTOMATOR_CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        automessages: {
          ...state.automessages,
          rows: state.automessages.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case CUSTOMER_AUTOMATOR_CLEAR_ACTION_MENU:
      return {
        ...state,
        automessages: {
          ...state.automessages,
          rows: state.automessages.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };
    default:
      return state;
  }
}

function prepareTableDate(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "NAME",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "name",
    },
    {
      name: "DESCRIPTION",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "description",
    },
    {
      name: "TRIGGER POINT",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "trigger_point.name",
    },
    {
      name: "USER ENTER SEGMENT",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "user_enter_segment.name",
    },
    {
      name: "USER EXIT SEGMENT",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "user_exit_segment.name",
    },

    {
      name: "DATE CREATED",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "created_at",
    },
  ];

  const rows = tableData.map((row) => ({
    checked: false,
    actionsMenu: false,
    id: row.automessage_id,

    values: labels.map((label) => {
      if (label.type === DATE_TYPE) {
        return moment(moment.utc(_.get(row, label.varName, label.defaultValue)))
          .local()
          .format(dateFormat);
      } else if (label.type === OBJECT) {
        return JSON.stringify(_.get(row, label.varName, label.defaultValue));
      } else if (label.type === NUMBER) {
        return _.get(row, label.varName, label.defaultValue)?.toFixed(2);
      }
      return _.get(row, label.varName, label.defaultValue);
    }),
  }));

  return { labels, rows, actions: true };
}
