import API_ENDPOINTS, { config } from "../consts/api";
import superAgentInstance from "../modules/api";
import { stripDashes } from "../modules/helpers";

export default class SegmentEndpoints {
  static getSegments(subdomain, page, pageSize) {
    return superAgentInstance("POST", API_ENDPOINTS.SEGMENT.getAllSegments(), {
      subdomain: stripDashes(subdomain),
      page,
      pageSize,
    }).set("x-parse-application-id", config.coreAppId);
  }

  static getMinifiedSegments(subdomain) {
    return superAgentInstance(
      "POST",
      API_ENDPOINTS.SEGMENT.getMinifiedSegments(),
      {
        subdomain: stripDashes(subdomain),
      }
    ).set("x-parse-application-id", config.coreAppId);
  }

  static addSegment(subdomain, name, match, criteria, { size }) {
    return superAgentInstance("POST", API_ENDPOINTS.SEGMENT.addSegment(), {
      subdomain: stripDashes(subdomain),
      name,
      match,
      criteria,
      size,
    }).set("x-parse-application-id", config.coreAppId);
  }

  static deleteSegment(subdomain, segmentId) {
    return superAgentInstance("POST", API_ENDPOINTS.SEGMENT.deleteSegment(), {
      subdomain: stripDashes(subdomain),
      segmentId,
    }).set("x-parse-application-id", config.coreAppId);
  }

  static getSegment(segmentId, subdomain) {
    return superAgentInstance(
      "GET",
      API_ENDPOINTS.SEGMENT.getSegment(segmentId, stripDashes(subdomain))
    ).set("x-parse-application-id", config.coreAppId);
  }

  static updateSegments(suitId) {
    return superAgentInstance(
      "PUT",
      API_ENDPOINTS.SEGMENT.updateSegments(suitId)
    );
  }
}
